.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: var(--black);
    animation: load 0.6s infinite linear;
}

@keyframes load {
    from {transform: rotate(0)}
    to {transform: rotate(360deg)}
}