:root {
    --gray100: #F2F4F7;
    --gray300: #D0D5DD;
    --gray400: #98A2B3;
    --gray500: #667085;
    --primary300: #D6BBFB;
    --primary400: #B692F6;
    --primary500: #9E77ED;
    --primary600: #7F56D9;
    --success500: #12B76A;
    --success50: #ECFDF3;
    --error50: #FEF3F2;
    --error500: #F04438;
    --white: #fff;
    --black: #101828;
    --shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

button {
    background-color: unset;
    cursor: pointer;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
  }

  div {
    box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
    text-decoration: none
    ;
  }

  button {
    border: none;
    outline: none;
  }