@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .wrapper {
        @apply  w-full flex justify-center items-center h-screen bg-zinc-50
    }
}

@layer base {
    body {
        @apply bg-zinc-50
    }
}

