.wrapper {
    width: 100%;
    min-height: 40px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    color: var(--white);
    height: 100%;
    width: 100%;
    background-color: var(--primary600);
    font-size: 16px;
}

.button:active {
    opacity: 70%;
}


.button.disable {
    opacity: 70%;
    cursor:not-allowed;
}



.button.rounder {
    border-radius: 8px;
}

.button.border {
    background-color: transparent;
    border: 1px solid var(--gray500);
    color: var(--black);
}
