.wrapper {
    height: 100vh;
    display: flex;
    background-color: #fafafa;
    align-items: center;
    justify-content: center;
}

.container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    box-shadow: var(--shadow);
    border-radius: 8px;
}

.title {
    width: 100%;
    font-size: 25px;
    text-align: left;
    font-weight: 600;
}

.errorApi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--error500);
    border: 0.5px solid var(--error500);
    margin-bottom: 5px;
    padding: 10px;
}

.errorApi p {
    font-size: 14px;
    text-align: left;
    margin: 0;
    padding: 0;
}

.errorApi svg {
    margin-right: 16px;
}

.errorMessage {
    display: block;
}
